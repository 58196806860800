<template>
  <div class="container">
    <div class="search">
      <van-icon class="search-icon" name="search" color="#666" />
      <input type="text" id="searchInput" ref="searchInput" placeholder="请输入地址查询">
    </div>
    <div id="map"></div>
    <!-- 自定义重置按钮，官方的没定位不能用   -->
    <div class="reset" @click="reset"></div>
    <div id="list" ref="list">
      <div class="item" v-for="item in addressList" :key="item.id" @click="selectAddress(item)">
        <van-icon class="icon" name="location" color="#1989fa" />
        <div class="address-text">
          <div class="name">{{item.name}}</div>
          <div class="address">{{item.address}}</div>
        </div>
        <div class="address-pic">

        </div>
      </div>
    </div>
  </div>
</template>

<script>

import AMap from 'maps'
import eventBus from "@/components/cell/GetAddress2/eventBus";

export default {
  props: {
    lng:String,
    lat:String
  },
  data() {
    return {
      map: null,
      marker: null,
      originalPos: [],
      position: [],
      addressList: [],
      selectedAddress:{},
      canBack:false,
      isFirst:true,
    };
  },
  mounted() {
    this.originalPos=[this.lng,this.lat]
    this.position=[this.lng,this.lat]
    this.init()
  },
  methods: {
    init() {
      let map = new AMap.Map('map', {
        resizeEnable: true,
        zoom: 15,
        center: this.position,
      });
      map.on('complete', function() {
        console.log('地图加载完成')
      });
      AMap.plugin([
        'AMap.ToolBar',
        'AMap.Scale',
        'AMap.Geolocation',
      ], function(){
        // 在图面添加工具条控件，工具条控件集成了缩放、平移、定位等功能按钮在内的组合控件
        map.addControl(new AMap.ToolBar());

        // 在图面添加比例尺控件，展示地图在当前层级和纬度下的比例尺
        map.addControl(new AMap.Scale());

        // // 在图面添加定位控件，用来获取和展示用户主机所在的经纬度位置
        // map.addControl(new AMap.Geolocation());
      });
      map.on('click',this.mapClick)
      this.map=map

      AMap.plugin('AMap.AutoComplete', ()=>{
        // 实例化Autocomplete
        var autoOptions = {
          // input 为绑定输入提示功能的input的DOM ID
          input: 'searchInput'
        }
        var autoComplete= new AMap.AutoComplete(autoOptions);
        console.log(AMap,'AMap.eventAMap.eventAMap.event');
        

        AMap.Event.addListener(autoComplete, "select", (e)=>{
          console.log('AutoComplete',e)
          let {lng,lat}=e.poi.location
          this.position = [lng, lat];
          this.init()
          this.addMarker()
        });//返回地点查询结果
      })
      this.addMarker()
    },
    addMarker() {
      if (this.marker) {
        this.map.remove(this.marker);
      }
      let marker = new AMap.Marker({
        position: this.position,
      });
      this.map.add(marker);
      this.marker=marker
      this.getAddressList()
    },
    mapClick(e) {
      let {lng,lat}=e.lnglat
      this.position=[lng,lat]
      this.addMarker()
    },
    getAddressList() {
      console.log(AMap,'AMapAMapAMap');
      
      AMap.plugin(["AMap.PlaceSearch"], ()=>{
        //构造地点查询类
        var placeSearch = new AMap.PlaceSearch({
          // type: '汽车服务|汽车销售|汽车维修|摩托车服务|餐饮服务|购物服务|生活服务|体育休闲服务|医疗保健服务|住宿服务|风景名胜|商务住宅|政府机构及社会团体|科教文化服务|交通设施服务|金融保险服务|公司企业|道路附属设施|地名地址信息|公共设施', // 兴趣点类别
          type: '汽车服务|汽车销售|汽车维修|摩托车服务|餐饮服务|购物服务|生活服务|体育休闲服务|医疗保健服务|住宿服务|风景名胜|商务住宅|政府机构及社会团体|科教文化服务|金融保险服务|公司企业|公共设施', // 兴趣点类别
          pageSize: 100, // 单页显示结果条数
          children:1,
          pageIndex: 1, // 页码
          city: "全国", // 兴趣点城市
          citylimit: false,  //是否强制限制在设置的城市内搜索
          // map: this.map, // 展现结果的地图实例
          // panel: this.$refs.list, // 结果列表将在此容器中进行展示。
          autoFitView: false, // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
        });

        placeSearch.searchNearBy('', this.position, 500, (status, result)=> {
          if (status === 'complete' && result.info === 'OK') {
            console.log('PlaceSearch',result)
            this.addressList=result.poiList.pois
            this.selectedAddress=result.poiList.pois[0]
            if (this.isFirst) {
              eventBus.$emit('selectedAddress',this.selectedAddress);
            }
            this.isFirst=!this.isFirst
          }
        });

      });
    },
    reset() {
      this.$refs.searchInput.value=''
      this.position=this.originalPos
      this.$nextTick(()=>{
        this.init()
      })
    },
    selectAddress(item) {
      eventBus.$emit('selectedAddress', item);
      this.$router.go(-1)
    }
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.container {
  width: 100vw;
  height: 100vh;
  position: relative;
}
.reset {
  background: #fff url(https://webapi.amap.com/theme/v1.3/markers/b/loc_gray.png) 50% 50% no-repeat;
  width: 70px;
  height: 70px;
  border: 1px solid #ccc;
  border-radius: 3px;
  right: 4px;
  position: absolute;
  top: calc(50vh - 200px);
  left: 20px;
  z-index: 999;
}
#map {
  width: 100vw;
  height: calc(50vh - 100px);
}
#list {
  max-height: 50vh;
  overflow-y: auto;
}
.item{
  min-height: 100px;
  padding:10px 20px;
  position: relative;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
}
.icon{
  position: relative;
  width: 50px;
}
.name{
  font-size: 30px;
}
.address {
  font-size: 28px;
  color: #999;
}
.search {
  height: 100px;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.search-icon{
  position: absolute;
  left: 40px;
}
.search input {
  height: 60px;
  line-height: 60px;
  width: calc(100vw - 40px);
  background-color: #eee;
  border-radius: 30px;
  padding: 0 30px 0 60px;
  font-size: 30px;
  color: #666;
}
</style>
